import {FC, useEffect, useState} from 'react';
import {useTypedSelector} from "../../../../store/hooks/useTypedSelector";
import {useAction} from "../../../../store/hooks/useAction";
import './betEngineTickets.css';
import Tickets from "../components/Tickets";
import {getStoragedCurrentOperator, getSessionStoragedCurrentOperator} from "../../auth";

const BetEngineTickets: FC = () => {
    const [paginationPage,setPaginationPage] = useState(1);
    const {getBetengineTicketsData} = useAction();
    const {betEngineTicketsData, loading} = useTypedSelector((state) => state.betengineTicketsReducer);
    let operatorID = getStoragedCurrentOperator();
    const sessionOperator = getSessionStoragedCurrentOperator();
    if (sessionOperator) {
        operatorID = sessionOperator;
    }
    const handleRefreshData = () => {
        getBetengineTicketsData(10,paginationPage,operatorID);
    }
    useEffect(() => {
        getBetengineTicketsData(10, paginationPage, operatorID);
    }, [paginationPage, operatorID]);

    return(
        <Tickets betEngineTicketsData={betEngineTicketsData} setPaginationPage={setPaginationPage} paginationPage={paginationPage} handleRefreshData={handleRefreshData}  />
    );
}


export {BetEngineTickets};

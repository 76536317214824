import React from "react";
import {Tooltip, OverlayTrigger} from "react-bootstrap";

export const ticketsStatusBadgeHelper = (statusCode: string, translations: any) => {
    switch(statusCode) {
        case 'pending':
            return <span className="badge badge-warning ">{translations[statusCode]?.length > 0 ? translations[statusCode] : statusCode}</span>
            break;
        case 'won':
            return <span className="badge badge-success">{translations[statusCode]?.length > 0 ? translations[statusCode] : statusCode}</span>
            break;
        case 'lost':
            return <span className="badge badge-danger">{translations[statusCode]?.length > 0 ? translations[statusCode] : statusCode}</span>
            break;
        case 'void':
            return <span className="badge badge-primary">{translations[statusCode]?.length > 0 ? translations[statusCode] : statusCode}</span>
            break;

        default:
            <span className="badge badge-light">{statusCode}</span>
            break;
    }
};

export const ticketEventStatusBadgeHelper = (statusCode: string, resultsVal: string, translations: any) => {
    switch(statusCode) {
        case 'open':
            return <span className="badge badge-warning">{translations[statusCode]?.length > 0 ? translations[statusCode] : statusCode}</span>;
        case 'new':
            return <span className="badge badge-dark">{translations[statusCode]?.length > 0 ? translations[statusCode] : statusCode}</span>;
        case 'canceled':
            return <span className="badge badge-secondary">{translations[statusCode]?.length > 0 ? translations[statusCode] : statusCode}</span>;
        case 'reserved':
            return <span className="badge badge-light">{translations[statusCode]?.length > 0 ? translations[statusCode] : statusCode}</span>;
        case 'calculating':
            return <span className="badge badge-warning">{translations[statusCode]?.length > 0 ? translations[statusCode] : statusCode}</span>;
        case 'resulted':
            if (resultsVal === 'pending') {
                return <span className="badge badge-warning">{translations[statusCode]?.length > 0 ? translations[statusCode] : statusCode}</span>;
            } else if (resultsVal === 'won') {
                return <span className="badge badge-success">{translations[statusCode]?.length > 0 ? translations[statusCode] : statusCode}</span>;
            }
            else if (resultsVal === 'lost') {
                return <span className="badge badge-danger">{translations[statusCode]?.length > 0 ? translations[statusCode] : statusCode}</span>;
            }
            else if (resultsVal === 'void') {
                return <span className="badge badge-success">{translations[statusCode]?.length > 0 ? translations[statusCode] : statusCode}</span>;
            }
            else {
                return <span className="badge badge-danger">{translations[statusCode]?.length > 0 ? translations[statusCode] : statusCode}</span>;
            }
        case 'closed':
            if (resultsVal === 'pending') {
                return <span className="badge badge-warning">{translations[statusCode]?.length > 0 ? translations[statusCode] : statusCode}</span>;
            } else if (resultsVal === 'won') {
                return <span className="badge badge-success">{translations[statusCode]?.length > 0 ? translations[statusCode] : statusCode}</span>;
            }
            else if (resultsVal === 'lost') {
                return <span className="badge badge-danger">{translations[statusCode]?.length > 0 ? translations[statusCode] : statusCode}</span>;
            }
            else if (resultsVal === 'void') {
                return <span className="badge badge-success">{translations[statusCode]?.length > 0 ? translations[statusCode] : statusCode}</span>;
            }
            else {
                return <span className="badge badge-danger">{translations[statusCode]?.length > 0 ? translations[statusCode] : statusCode}</span>;
            }
        default:
            return <span className="badge badge-danger">{statusCode}</span>;
    }
};


export const updateDate = (dateString: string) => {
    const dateObj = new Date(dateString);

    if (isNaN(dateObj.getTime())) {
        return {
            error: 'Invalid date format'
        };
    }

    const year = dateObj.getFullYear();
    const month = String(dateObj.getMonth() + 1).padStart(2, '0');
    const day = String(dateObj.getDate()).padStart(2, '0');
    const hours = String(dateObj.getHours()).padStart(2, '0');
    const minutes = String(dateObj.getMinutes()).padStart(2, '0');
    const seconds = String(dateObj.getSeconds()).padStart(2, '0');

    return {
        formattedDate: `${hours}:${minutes}:${seconds} ${day}-${month}-${year}`,
        shortformattedDate: `${day}.${month} ${hours}:${minutes}`,
        date: `${year}-${month}-${day}`,
        time: `${hours}:${minutes}`
    };
};

export const ticketTypeBadgeHelper = (typeValue: string) => {
    switch(typeValue) {
        case 'quicktip':
            return <OverlayTrigger placement="top" overlay={<Tooltip>{typeValue}</Tooltip>}>
                        <span className="badge badge-warning ">QT</span>
                   </OverlayTrigger>
            break;
        case 'quickbet':
            return <OverlayTrigger placement="top" overlay={<Tooltip>{typeValue}</Tooltip>}>
                <span className="badge badge-secondary">QB</span>
            </OverlayTrigger>
            break;
        case 'smart_jackpot':
            return <OverlayTrigger placement="top" overlay={<Tooltip>{typeValue}</Tooltip>}>
                        <span className="badge badge-success" title={typeValue}>SJ</span>
                    </OverlayTrigger>
            break;
        case 'smartbet':
            return <OverlayTrigger placement="top" overlay={<Tooltip>{typeValue}</Tooltip>}>
                <span className="badge badge-success">SB</span>
            </OverlayTrigger>
            break;
        case 'jackpot':
            return <OverlayTrigger placement="top" overlay={<Tooltip>{typeValue}</Tooltip>}>
                        <span className="badge badge-primary" title={typeValue}>JP</span>
                   </OverlayTrigger>
            break;
        case 'superbet':
            return <OverlayTrigger placement="top" overlay={<Tooltip>{typeValue}</Tooltip>}>
                <span className="badge badge-info" title={typeValue}>SB</span>
            </OverlayTrigger>
            break;

        default:
            <OverlayTrigger placement="top" overlay={<Tooltip>{typeValue}</Tooltip>}>
                <span className="badge badge-info" title={typeValue}>{typeValue}</span>
            </OverlayTrigger>
            break;
    }
}
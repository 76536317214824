import React, {FC, useEffect} from "react";
import {useAction} from "../../../../../store/hooks/useAction";
import {useTypedSelector} from "../../../../../store/hooks/useTypedSelector";
import {useIntl} from "react-intl";
import {GraphChart} from "../../componenets/GraphChart";
interface TI {
    currency: string,
    operator: string
}

const DailyGGRCurrentMonthGraphContainer: FC<TI> = ({currency, operator}) => {
    const {dailyGGRCurrentMonthGraph} = useAction();
    const {dailyGGRCurrentMonthGraphData} = useTypedSelector((state) => state.dailyGGRCurrentMonthGraphReducer);

    useEffect(()=> {
        if (operator) {
            dailyGGRCurrentMonthGraph(operator);
        }
    }, [operator])
    return(
        <>
            {
                Object.keys(dailyGGRCurrentMonthGraphData).length > 0 ?
                    <GraphChart data={dailyGGRCurrentMonthGraphData} currencyVal={currency} title={'DASHBOARD.dailyGGRCurrentMonth'}/>
                    :null
            }
        </>
    )
}

export default DailyGGRCurrentMonthGraphContainer;
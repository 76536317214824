/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import {FC} from 'react'
import {Routes, Route, BrowserRouter, Navigate} from 'react-router-dom'
import {PrivateRoutes} from './PrivateRoutes'
import {ErrorsPage} from '../modules/errors/ErrorsPage'
import {Logout, AuthPage, useAuth} from '../modules/auth'
import {App} from '../App'
import * as authHelper from '../modules/auth/core/AuthHelpers'

/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */


/*const {PUBLIC_URL} = process.env*/

const AppRoutes: FC = () => {
  const {currentUser} = useAuth();

    const queryParams = new URLSearchParams(window.location.search);
    const nameParam = queryParams.get('operator');
    const currencyParam = queryParams.get('currency');

    if (nameParam) {
        authHelper.setSessionStorageCurrentOperator(nameParam);
    }
    if (currencyParam) {
        authHelper.setSessionStorageOperatorCurrency(currencyParam);
    }
    return (
    <BrowserRouter basename={'/'}>
      <Routes>
        <Route element={<App />}>
          <Route path='error/*' element={<ErrorsPage />} />
          <Route path='logout' element={<Logout />} />
          {currentUser ? (
            <>
              <Route path='/*' element={<PrivateRoutes />} />
                {
                    currentUser.is_superuser ?
                        <Route index element={<Navigate to='/dashboard' />} />
                        : <Route index element={<Navigate to='/dashboard' />} />
                }
            </>
          ) : (
              <>
                  <Route path='auth/*' element={<AuthPage />} />
                  <Route path='*' element={<Navigate to='/auth' />} />
              </>
          )}
        </Route>
      </Routes>
    </BrowserRouter>
  )
}

export {AppRoutes}
